<template>
  <div>
    <Flex-Input />
  </div>
</template>

<script>
import FlexInput from './Input'

export default {
  name: 'TestPage',
  components: {
    FlexInput
  }
}
</script>

<style></style>
