<template>
  <div class="flex-inputer">
    <textarea class="flex-inputer_input" rows="1" v-model="input" />
  </div>
</template>

<script>
export default {
  name: 'FlexInput',
  data () {
    return {
      input:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus ratione id rem expedita distinctio illum placeat necessitatibus ipsum, totam quis eveniet accusamus recusandae suscipit exercitationem dicta nemo, doloremque, repudiandae dolorum.'
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-inputer {
  box-sizing: border-box;
  * {
    box-sizing: inherit;
  }
  &_input {
    display: block;
    width: 100%;
    resize: none;
    line-height: 1.2em;
    padding: 10px;
    border: 0;
    outline: none;
    overflow: hidden;
    border: 1px solid #f00;
  }
}
</style>
